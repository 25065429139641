import styled from "@emotion/styled";
import { ReactComponent as Avantage1 } from "../avantage_1.svg";
import { ReactComponent as Avantage2 } from "../avantage_2.svg";
import { ReactComponent as Avantage3 } from "../avantage_3.svg";
import pattern from "../pattern.svg";
import { ReactComponent as WaveBlueBottom } from "../wave_blue_bottom.svg";
import { ReactComponent as WaveBlueTop } from "../wave_blue_top.svg";
import { ReactComponent as WaveSkyBottom } from "../wave_sky_bottom.svg";
import { ReactComponent as WaveSkyTop } from "../wave_sky_top.svg";
import { InlineWidget } from "react-calendly";
import Navbar from "../components/Navbar";

const HeroSection = styled.section`
  background-image: linear-gradient(
    110deg,
    #eef2f6 25.5%,
    #fff 39%,
    #eef2f6 49%,
    #fff 55%,
    #eef2f6 59%
  ); ;
`;

function Contact() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden relative text-center">
      <main className="flex-grow">
        {/* <Navbaralt/> */}
        <Navbar />
        <section
          id="demo"
          style={{
            backgroundImage: `url(assets/demo.jpg)`,
          }}
        >
          <div className="max-w-6xl mx-auto py-20 md:py-32 px-6 sm:px-8 relative">
            <div className="flex flex-wrap">
              <div className="text-left max-w-xl">
                <div className="font-semibold text-xl text-sky-600 mb-2">
                  Une question ?
                </div>
                <h2 className="text-2xl font-bold tracking-tight mb-10">
                  Contactez nous !
                </h2>
                <p className="text-xl font-light mt-20 mb-10">
                  Pour ça, rien de plus simple. <strong>Réservez-un créneau sur cette page</strong> pour échanger avec nous, ou <strong>écrivez directement à</strong> <br/><br/><span className="font-semibold text-xl text-sky-600 mb-2"><a href="mailto:contact@getmygarage.app">contact@getmygarage.app</a></span>
                  <br />
                  <br />
                  A très vite !
                  <br /> <br/>L'équipe myGarage
                </p>
              </div>
              <div className="flex-1 mx-4">
                <div className="mx-auto max-w-md">
                  <InlineWidget url="https://calendly.com/mygarage/30min?primary_color=0284c7" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="text-left">
        <div className="py-12 md:py-16">
          <div className="max-w-6xl mx-auto px-6 sm:px-8">
            <div className="grid gap-2 text-gray-500 text-center">
              <p>Made with ❤️ in the Alps</p>
              <p>© 2022 Cyclic SAS. Tous droits réservés.</p>
              <a
                className="hover:underline"
                href="https://my-garage.notion.site/Conditions-g-n-rales-d-utilisation-bd34c63c124746acacaba4164c96a632"
              >
                Conditions Générales d'Utilisation
              </a>
              <a
                className="hover:underline"
                href="https://my-garage.notion.site/Charte-sur-le-respect-de-la-vie-priv-e-e0160a4bfe704df59c9b543e9972bb16"
              >
                Charte vie privée
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Contact;
