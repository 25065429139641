import styled from "@emotion/styled";
import feature1 from "../feature1.png";
import feature2 from "../feature2.png";
import feature3 from "../feature3.png";
import feature4 from "../feature4.png";
import pattern from "../pattern.svg";
import { ReactComponent as WaveBlueBottom } from "../wave_blue_bottom.svg";
import { ReactComponent as WaveBlueTop } from "../wave_blue_top.svg";
import { ReactComponent as WaveSkyBottom } from "../wave_sky_bottom.svg";
import { ReactComponent as WaveSkyTop } from "../wave_sky_top.svg";
import Navbar from "../components/Navbar";

const HeroSection = styled.section`
  background-image: linear-gradient(
    110deg,
    #eef2f6 25.5%,
    #fff 39%,
    #eef2f6 49%,
    #fff 55%,
    #eef2f6 59%
  ); ;
`;

function Application() {
  // const { rive, RiveComponent } = useRive({
  //   src: "assets/new_file.riv",
  //   // src: "https://cdn.rive.app/animations/vehicles.riv",
  //   autoplay: true,
  //   // stateMachines: ["machine1"],
  //   onStateChange: (event) => {
  //     /* @ts-ignore */
  //     console.log(event.data[0]);
  //   },
  // });

  // const success = useStateMachineInput(rive, "machine1", "success");
  // const handsUp = useStateMachineInput(rive, "machine1", "hands_up");
  // const handsDown = useStateMachineInput(rive, "machine1", "hands_down");

  // handsUp?.fire();

  // const { rive, RiveComponent } = useRive({
  //   src: "https://cdn.rive.app/animations/vehicles.riv",
  //   stateMachines: "bumpy",
  //   autoplay: true,
  // });

  // const bumpInput = useStateMachineInput(rive, "bumpy", "bump");

  return (
    <div className="flex flex-col min-h-screen overflow-hidden relative text-center">
      {/* <header className="absolute w-full z-30">
        <div className="max-w-6xl mx-auto px-6 sm:px-8">
          <div className="flex items-center justify-between h-20">
            <div className="flex-shrink-0 mr-4">
              <a className="flex flex-row" aria-label="Logo" href="/">
                <img
                  loading="lazy"
                  className="h-8 fill-current text-purple-600"
                  src={logo}
                  alt="Logo"
                />
              </a>
            </div>
            <nav className="hidden md:flex md:flex-grow">
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <a
                    className="text-slate-500 hover:text-[#0369a1] dark:text-slate-400 dark:hover:text-[#0369a1] px-4 py-2 flex items-center transition duration-150 ease-in-out hover:bg-neutral-200 rounded-md"
                    href="/customers"
                  >
                    L'application
                  </a>
                </li>
                <li>
                  <a
                    className="text-slate-500 hover:text-[#0369a1] dark:text-slate-400 dark:hover:text-[#0369a1] px-4 py-2 flex items-center transition duration-150 ease-in-out hover:bg-neutral-200 rounded-md"
                    href="/features"
                  >
                    Pour les entreprises
                  </a>
                </li>
                <li>
                  <a
                    className="btn-sm text-white bg-gray-700 hover:bg-gray-600 px-4 py-3 rounded-lg ml-3"
                    href="#demo"
                  >
                    Demander une démo
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header> */}
      <main className="flex-grow">
        <Navbar />
        <HeroSection className="pb-36 min-h-screen relative border-0 border-purple-500 overflow-hidden">
          <div
            style={{
              maskImage: "radial-gradient(rgb(255,255,255, 0.1), transparent)",
              WebkitMaskImage:
                "radial-gradient(rgb(255,255,255, 0.1), transparent)",
              backgroundImage: `url(${pattern})`,
            }}
            className="h-2/3 w-1/2 bg-opacity-10 absolute top-20 -left-40"
          ></div>
          <div className="h-[60vh] w-[60vw] rounded-full filter blur-2xl bg-sky-400 bg-opacity-10 absolute right-0 translate-x-52 rotate-[-30deg]"></div>
          <div className="mx-[2vw] pt-0 px-0 sm:px-8 relative flex md:flex-row md:justify-between flex-col left-0 border-0 border-blue-500">
            <div className="mx-[2vw] text-left pb-12 md:mt-[15vw] mt-20 md:mt-40 md:pb-16 relative border-0 border-red-500">
              <div className="">
                <h1 className="mb-10 text-slate-800 font-bold text-3xl sm:text-4xl lg:text-5xl tracking-tight text-left">
                  <span className="text-transparent mix-blend-multiply bg-clip-text bg-gradient-to-r from-sky-600 to-[#035D8C] text-4xl sm:text-5xl lg:text-6xl">
                    Pourquoi acheter quand <br /> on peut emprunter ?
                  </span>
                </h1>
                <p className="text-slate-600 text-lg mx-auto mb-12">
                  myGarage, le premier réseau social de partage d'objets.
                </p>
                <div
                  className="flex flex-row justify-left items-center gap-10"
                  id="downloadlinks"
                >
                  <a href="https://play.google.com/store/apps/details?id=harijoe.mygarage.prod&gl=FR">
                    <img
                      src="/assets/google-play-badge.png"
                      alt="Playstore"
                      className="h-[12vw] max-h-16"
                    />
                  </a>
                  <a href="https://apps.apple.com/fr/app/mygarage-mon-garage-partag%C3%A9/id6444388308">
                    <img
                      src="/assets/Appstorebadge.png"
                      alt="Appstore"
                      className="h-[10vw] max-h-14"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="relative w-[60%] mx-auto md:max-w-[20.5vw] md:top-36 border-0 flex-0 flex items-center m-auto">
              <img
                src="/assets/iphone13frame.png"
                alt="appframe"
                className="absolute z-30 w-full top-[0.25%] h-full object-contain"
              />
              <div className="w-full flex items-center justify-center pt-[5.5%] pb-[5%]">
                <video
                  src="/assets/output.mp4"
                  autoPlay
                  loop
                  muted
                  poster="/assets/output-poster.jpeg"
                  className="w-[87.5%] z-20 rounded-2xl"
                />
              </div>
            </div>
          </div>
        </HeroSection>
        <div className="md:overflow-hidden" id="usecases">
          <section className="bg-white md:h-screen h-full">
            <div className="max-w-6xl mx-auto py-32 px-6 sm:px-8 relative">
              <div className="flex justify-center">
                <div className="font-semibold text-xl text-sky-600 mb-2">
                  {/*Fonctionnement*/}
                </div>
              </div>
              <h2 className="text-transparent mix-blend-multiply bg-clip-text bg-gradient-to-r from-sky-600 to-[#035D8C] text-4xl font-bold tracking-tight sm:text-4xl mb-20">
                Je construis une communauté de partage d'objets sur mesure.
              </h2>
              <div className="flex items-center flex-col md:flex-row md:items-start">
                <div className="flex-1 mx-8 mb-20 md:mb-0 flex flex-col items-center justify-center">
                  <p className="text-lg text-slate-600 my-8">
                    Je partage <strong>mes outils</strong> avec mes{" "}
                    <strong>voisins</strong>
                  </p>
                  <img
                    className="w-[60%] md:w-full"
                    srcSet="/assets/brricolage-webflow.png"
                    alt=""
                  />
                </div>
                <div className="flex-1 mx-8 mb-20 md:mb-0 flex flex-col items-center justify-center">
                  <p className="text-lg text-slate-600 my-8">
                    J'emprunte du <strong>matériel de jardin</strong> à mes{" "}
                    <strong>collègues</strong>
                  </p>
                  <img
                    className="w-[60%] md:w-full"
                    srcSet="/assets/jardinage-webflow.png"
                    alt=""
                  />
                </div>
                <div className="flex-1 mx-8 mb-20 md:mb-0 flex flex-col items-center justify-center">
                  <p className="text-lg text-slate-600 my-8">
                    Je prête du matériel de <strong>sport</strong> à mes{" "}
                    <strong>amis</strong>
                  </p>
                  <img
                    className="w-[60%] md:w-full"
                    srcSet="/assets/sport-webflow.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
          <div className="w-full h-20 relative">
            <WaveBlueTop className="absolute h-12 bottom-0" />
            <WaveSkyTop className="absolute" />
          </div>
        </div>
        {/* <div className="w-full h-32"></div> */}
        <section className="bg-[#025D8C] text-white" id="features">
          <div className="max-w-6xl mx-auto py-32 px-6 sm:px-8 relative">
            <div className="font-semibold text-xl text-sky-300 mb-2">
              {/*Pourquoi l'adopter*/}
            </div>
            <h2 className="text-4xl font-bold tracking-tight sm:text-5xl mb-32">
              Comment ça marche ?
            </h2>
            <div className="flex flex-col-reverse md:flex-row mb-32 relative">
              <div className="absolute -top-12 -left-32 h-64 w-64 bg-white bg-opacity-10 rotate-12 rounded-xl" />
              <div className="flex-1 flex">
                <div className="w-12 self-start shrink-0">
                  <div
                    className="text-4xl font-black text-[#025D8C] rounded-md"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, transparent 0%, #bae6fd 0, #bae6fd)",
                    }}
                  >
                    1.
                  </div>
                </div>
                <div className="flex flex-col text-left ml-8">
                  <h3 className="text-2xl mb-8">J'enregistre mes produits</h3>
                  <p className="font-light">
                    En 3 clics, avec ou sans photo, j'ajoute un produit.
                    <br /> A prêter, à louer, à donner ou à vendre !
                  </p>
                </div>
              </div>
              <div className="flex-1 flex min-w-[40%] max-w-[80%] mx-auto mb-10 md:mb-0">
                <img src={feature1} className="h-[80%]"></img>
              </div>
            </div>
            <div className="flex flex-col md:flex-row mb-32 relative">
              <div className="absolute -top-12 -right-32 h-64 w-64 bg-white bg-opacity-10 rotate-12 rounded-xl" />
              <div className="flex-1 flex min-w-[40%] max-w-[80%] mx-auto mb-10 md:mb-0">
                <img src={feature2} className="h-[80%]"></img>
              </div>
              <div className="flex-1 flex">
                <div className="w-12 h-w-12 self-start shrink-0">
                  <div
                    className="text-4xl font-black text-[#025D8C] rounded-md"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, transparent 0%, #bae6fd 0, #bae6fd)",
                    }}
                  >
                    2.
                  </div>
                </div>
                <div className="flex flex-col text-left ml-8">
                  <h3 className="text-2xl mb-8">
                    Je me note ce que je prête, et à qui
                  </h3>
                  <p className="font-light">
                    Pour ne plus jamais oublier qui je dois relancer pour
                    récupérer mes objets !
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row mb-10 relative">
              <div className="absolute -top-12 -left-32 h-64 w-64 bg-white bg-opacity-10 rotate-12 rounded-xl" />
              <div className="flex-1 flex">
                <div className="w-12 h-w-12 self-start shrink-0">
                  <div
                    className="text-4xl font-black text-[#025D8C] rounded-md"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, transparent 0%, #bae6fd 0, #bae6fd)",
                    }}
                  >
                    3.
                  </div>
                </div>
                <div className="flex flex-col text-left ml-8">
                  <h3 className="text-2xl mb-8">
                    Je créé ou rejoins un groupe, et j'invite mes proches !
                  </h3>
                  <p className="font-light">
                    Qu'il s'agisse de créer un groupe de voisins, de collègues,
                    ou pour les adhérents de mon club - avec un lien, j'invite
                    tout le monde à participer à ma communauté de partage.
                  </p>
                </div>
              </div>
              <div className="flex-1 flex min-w-[40%] max-w-[80%] mx-auto mb-10 md:mb-0">
                <img src={feature3} className="h-[80%]"></img>
              </div>
            </div>
            <div className="flex flex-col md:flex-row mb-32 relative mt-44">
              <div className="absolute -top-12 -right-32 h-64 w-64 bg-white bg-opacity-10 rotate-12 rounded-xl" />
              <div className="flex-1 flex min-w-[40%] max-w-[80%] mx-auto mb-10 md:mb-0">
                <img src={feature4} className="h-[80%]"></img>
              </div>
              <div className="flex-1 flex">
                <div className="w-12 h-w-12 self-start shrink-0">
                  <div
                    className="text-4xl font-black text-[#025D8C] rounded-md"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg, transparent 0%, #bae6fd 0, #bae6fd)",
                    }}
                  >
                    4.
                  </div>
                </div>
                <div className="flex flex-col text-left ml-8">
                  <h3 className="text-2xl mb-8">Je cherche et j'emprunte !</h3>
                  <p className="font-light">
                    Un seul endroit, pour chercher parmi tous les objets de ma
                    communauté.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="w-full h-16 relative">
          <WaveBlueBottom className="absolute h-12" />
          <WaveSkyBottom className="absolute" />
        </div>
        <section id="contact">
          <div className="max-w-6xl mx-auto py-32 px-6 sm:px-8 relative">
            <div className="font-semibold text-xl text-sky-600 mb-2">
              {/*Témoignages*/}
            </div>
            <h2 className="text-4xl font-bold tracking-tight sm:text-5xl mb-10">
              Nous contacter
            </h2>
            <p className="hover:text-[#035D8C]">
              <a href="mailto:contact@getmygarage.app">
                contact@getmygarage.app
              </a>
            </p>
          </div>
        </section>
      </main>
      <footer className="text-left">
        <div className="py-12 md:py-16">
          <div className="max-w-6xl mx-auto px-6 sm:px-8">
            <div className="grid gap-2 text-gray-500 text-center">
              <p>Made with ❤️ in the Alps</p>
              <p>© 2022 Cyclic SAS. Tous droits réservés.</p>
              <a
                className="hover:underline"
                href="https://my-garage.notion.site/Conditions-g-n-rales-d-utilisation-bd34c63c124746acacaba4164c96a632"
              >
                Conditions Générales d'Utilisation
              </a>
              <a
                className="hover:underline"
                href="https://my-garage.notion.site/Charte-sur-le-respect-de-la-vie-priv-e-e0160a4bfe704df59c9b543e9972bb16"
              >
                Charte vie privée
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Application;
