import clsx from "clsx";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../logo.svg";

function Navbar() {
  const [open, setOpen] = useState(false);
  return (
    <>
      {/* <header className="fixed w-100vw mx-[5vw] z-40 bg-white rounded-full shadow-md bg-opacity-95 top-5 inset-x-0">
        <div className="max-w-6xl mx-auto px-6 sm:px-8">
          <div className="flex items-center justify-between h-20">
            <div className="flex-shrink-0 mr-4">
              <a className="flex flex-row ml-5" aria-label="Logo" href="/">
                <img
                  loading="lazy"
                  className="h-8 fill-current text-purple-600"
                  src={logo}
                  alt="Logo"
                />
              </a>
            </div>
            <nav className="hidden md:flex md:flex-grow">
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <a
                    className="text-slate-500 font-semibold rounded-full hover:text-[#0369a1] dark:text-slate-400 dark:hover:text-[#0369a1] px-4 py-2 flex items-center transition duration-150 ease-in-out hover:bg-neutral-100 rounded-md"
                    href="/application#usecases"
                  >
                    A quoi ça sert ?
                  </a>
                </li>
                <li>
                  <a
                    className="text-slate-500 font-semibold rounded-full hover:text-[#0369a1] dark:text-slate-400 dark:hover:text-[#0369a1] px-4 py-2 flex items-center transition duration-150 ease-in-out hover:bg-neutral-100 rounded-md"
                    href="/application#features"
                  >
                    Comment ça marche ?
                  </a>
                </li>
                <li>
                  <a
                    className="text-slate-500 font-semibold rounded-full hover:text-[#0369a1] dark:text-slate-400 dark:hover:text-[#0369a1] px-4 py-2 flex items-center transition duration-150 ease-in-out hover:bg-neutral-100 rounded-md"
                    href="/enterprises"
                  >
                    Pour les entreprises
                  </a>
                </li>
                <li>
                  <a
                    className="btn-sm font-semibold text-white bg-gray-700 hover:bg-gray-600 px-4 py-3 rounded-lg ml-3 scroll-smooth"
                    href="#contact"
                  >
                    Nous contacter
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header> */}
      <nav className="px-2 sm:px-4 py-2.5 rounded-full dark:bg-gray-900">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <a href="/#" className="flex items-center">
            <img
              loading="lazy"
              className="h-6 fill-current text-purple-600"
              src={logo}
              alt="Logo"
            />
          </a>
          <button
            onClick={() => setOpen(!open)}
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            className={clsx("w-full md:block md:w-auto", open ? "" : "hidden")}
            id="navbar-default"
          >
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              {/* <li>
                <NavLink
                  to="#"
                  className={({ isActive }) =>
                    clsx(
                      "block py-2 pl-3 pr-4 text-white bg-[#0369a1] rounded md:bg-transparent md:p-0 dark:text-white",
                      isActive
                        ? "text-white bg-[#0369a1] md:bg-transparent md:text-[#0369a1]"
                        : "md:text-gray-700"
                    )
                  }
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/application"
                  className={({ isActive }) =>
                    clsx(
                      "block py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0 dark:text-white",
                      isActive
                        ? "text-white bg-[#0369a1] md:bg-transparent md:text-[#0369a1]"
                        : "md:text-gray-700"
                    )
                  }
                >
                  L'application
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/enterprises"
                  className={({ isActive }) =>
                    clsx(
                      "block py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0 dark:text-white",
                      isActive
                        ? "text-white bg-[#0369a1] md:bg-transparent md:text-[#0369a1]"
                        : "md:text-gray-700 my-0"
                    )
                  }
                >
                  Pour les entreprises
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className="md:bg-[#0369a1] md:text-white md:px-3 md:py-3 md:rounded-md"
                >
                  Nous contacter
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
