import styled from "@emotion/styled";
import { ReactComponent as Avantage1 } from "../avantage_1.svg";
import { ReactComponent as Avantage2 } from "../avantage_2.svg";
import { ReactComponent as Avantage3 } from "../avantage_3.svg";
import pattern from "../pattern.svg";
import { ReactComponent as WaveBlueBottom } from "../wave_blue_bottom.svg";
import { ReactComponent as WaveBlueTop } from "../wave_blue_top.svg";
import { ReactComponent as WaveSkyBottom } from "../wave_sky_bottom.svg";
import { ReactComponent as WaveSkyTop } from "../wave_sky_top.svg";
import { InlineWidget } from "react-calendly";
import Navbar from "../components/Navbar";

const HeroSection = styled.section`
  background-image: linear-gradient(
    110deg,
    #eef2f6 25.5%,
    #fff 39%,
    #eef2f6 49%,
    #fff 55%,
    #eef2f6 59%
  ); ;
`;

function Enterprises() {
  // const { rive, RiveComponent } = useRive({
  //   src: "assets/new_file.riv",
  //   // src: "https://cdn.rive.app/animations/vehicles.riv",
  //   autoplay: true,
  //   // stateMachines: ["machine1"],
  //   onStateChange: (event) => {
  //     /* @ts-ignore */
  //     console.log(event.data[0]);
  //   },
  // });

  // const success = useStateMachineInput(rive, "machine1", "success");
  // const handsUp = useStateMachineInput(rive, "machine1", "hands_up");
  // const handsDown = useStateMachineInput(rive, "machine1", "hands_down");

  // handsUp?.fire();

  // const { rive, RiveComponent } = useRive({
  //   src: "https://cdn.rive.app/animations/vehicles.riv",
  //   stateMachines: "bumpy",
  //   autoplay: true,
  // });

  // const bumpInput = useStateMachineInput(rive, "bumpy", "bump");

  return (
    <div className="flex flex-col min-h-screen overflow-hidden relative text-center">
      {/* <header className="absolute w-full z-30">
        <div className="max-w-6xl mx-auto px-6 sm:px-8">
          <div className="flex items-center justify-between h-20">
            <div className="flex-shrink-0 mr-4">
              <a className="flex flex-row" aria-label="Logo" href="/">
                <img
                  loading="lazy"
                  className="h-8 fill-current text-purple-600"
                  src={logo}
                  alt="Logo"
                />
              </a>
            </div>
            <nav className="hidden md:flex md:flex-grow">
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <a
                    className="text-slate-500 hover:text-[#0369a1] dark:text-slate-400 dark:hover:text-[#0369a1] px-4 py-2 flex items-center transition duration-150 ease-in-out hover:bg-neutral-200 rounded-md"
                    href="/customers"
                  >
                    L'application
                  </a>
                </li>
                <li>
                  <a
                    className="text-slate-500 hover:text-[#0369a1] dark:text-slate-400 dark:hover:text-[#0369a1] px-4 py-2 flex items-center transition duration-150 ease-in-out hover:bg-neutral-200 rounded-md"
                    href="/features"
                  >
                    Pour les entreprises
                  </a>
                </li>
                <li>
                  <a
                    className="btn-sm text-white bg-gray-700 hover:bg-gray-600 px-4 py-3 rounded-lg ml-3"
                    href="#demo"
                  >
                    Demander une démo
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header> */}
      <main className="flex-grow">
        {/* <Navbaralt/> */}
        <Navbar />
        <HeroSection className="pb-36 min-h-screen relative overflow-hidden">
          <div
            style={{
              maskImage: "radial-gradient(rgb(255,255,255, 0.1), transparent)",
              WebkitMaskImage:
                "radial-gradient(rgb(255,255,255, 0.1), transparent)",
              backgroundImage: `url(${pattern})`,
            }}
            className="h-2/3 w-1/2 bg-opacity-10 absolute top-20 -left-40"
          ></div>
          <div className="h-[60vh] w-[60vw] rounded-full filter blur-2xl bg-sky-400 bg-opacity-10 absolute right-0 translate-x-52 rotate-[-30deg]"></div>
          <div className="max-w-6xl mx-auto pt-20 md:pt-40 px-6 sm:px-8 relative">
            <div className="max-w-4xl mx-auto text-center pb-12 md:pb-16 relative">
              <div className="text-center">
                <h1 className="mb-10 text-slate-800 font-bold text-3xl sm:text-4xl lg:text-5xl tracking-tight text-center">
                  Créez une <p> </p>
                  <span className="text-transparent mix-blend-multiply bg-clip-text bg-gradient-to-r from-sky-600 to-[#035D8C] text-4xl sm:text-5xl lg:text-6xl">
                    communauté de partage
                  </span>
                  <p> </p>dans votre entreprise
                </h1>
                <p className="text-slate-600 text-lg max-w-2xl mx-auto mb-12">
                  Déployez myGarage, le premier réseau social de partage
                  d'objets entre particuliers, au sein de votre entreprise.
                </p>
              </div>
            </div>
            <div>
              <div className="flex justify-evenly items-stretch">
                <div className="flex-none hidden md:flex flex-col justify-between">
                  <div className="flex relative ">
                    <img
                      className="absolute left-2 top-0 w-14 z-10"
                      src="/assets/animation_user_1.png"
                      alt="user1"
                    />
                    <div className="bg-white shadow-lg rounded-lg text-left p-4 pt-10 pr-10 mt-7 relative">
                      <div className="font-bold">Thomas</div>
                      <div className="text-slate-500">Expert comptable</div>
                      <img
                        className="w-10 h-10 absolute top-2 right-2"
                        src="assets/animation_icon_velo.png"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="flex relative">
                    <img
                      className="absolute left-2 top-0 w-14 z-10"
                      src="/assets/animation_user_2.png"
                      alt="user1"
                    />
                    <div className="bg-white shadow-lg rounded-lg text-left p-4 pt-10 pr-10 mt-7 relative">
                      <div className="font-bold">Aimée</div>
                      <div className="text-slate-500">Cheffe de produit</div>
                      <img
                        className="w-10 h-10 absolute top-2 right-2"
                        src="assets/animation_icon_perceuse.png"
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-col items-center justify-center ">
                    <img
                      className="rounded-full w-[100px] h-[100px] -mb-[50px] z-10"
                      src="assets/animation_office.jpg"
                      alt="icon"
                    />
                    <div className="bg-gradient-to-r from-sky-600 to-[#035D8C] rounded-xl w-72 text-white flex flex-col items-center justify-center pt-20 pb-12 shadow-lg">
                      <div>Garage partagé de</div>
                      <div className="font-bold mb-6">Mon Entreprise</div>
                      <div className="mb-2">21 membres</div>
                      <div className="flex -space-x-4 mb-6">
                        {Array.from(Array(3).keys()).map((e) => {
                          return (
                            <img
                              alt="gallery"
                              className="w-12 h-12 rounded-full border border-white"
                              src={`assets/animation_member_${e + 1}.png`}
                            />
                          );
                        })}
                        <img
                          alt="gallery"
                          className="w-12 h-12 rounded-full border border-white"
                          src={`assets/animation_user_1.png`}
                        />
                        <img
                          alt="gallery"
                          className="w-12 h-12 rounded-full border border-white"
                          src={`assets/animation_user_1.png`}
                        />
                        <img
                          alt="gallery"
                          className="w-12 h-12 rounded-full border border-white"
                          src={`assets/animation_user_3.png`}
                        />
                      </div>
                      <div>253 objets partagés</div>
                    </div>
                  </div>
                </div>
                <div className="flex-none hidden md:block">
                  <div className="flex relative">
                    <img
                      className="absolute left-2 top-0 w-14 z-10"
                      src="/assets/animation_user_3.png"
                      alt="user1"
                    />
                    <div className="bg-white shadow-lg rounded-lg text-left p-4 pt-10 pr-10 mt-7 relative">
                      <div className="font-bold">Carole</div>
                      <div className="text-slate-500">Ingénieure produit</div>
                      {/* <img
            className="w-10 h-10 absolute top-2 right-2"
            src="assets/animation_icon_velo.png"
            alt="icon"
          /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeroSection>
        <section className="bg-white" id="features">
          <div className="max-w-6xl mx-auto py-32 px-6 sm:px-8 relative">
            <div className="flex justify-center">
              <div className="font-semibold text-xl text-sky-600 mb-2">
                {/*Fonctionnement*/}
              </div>
            </div>
            <h2 className="text-slate-900 text-4xl font-bold tracking-tight sm:text-5xl mb-20">
              Comment ça marche ?
            </h2>
            <div className="flex items-center flex-col md:flex-row md:items-start">
              <div className="flex-1 mx-8 mb-20 md:mb-0 flex flex-col items-center justify-center">
                <div
                  className="text-4xl font-black text-[#025D8C]"
                  style={{
                    backgroundImage:
                      "linear-gradient(180deg, transparent 55%, #bae6fd 0, #bae6fd)",
                  }}
                >
                  1.
                </div>
                <p className="text-lg text-slate-600 my-8">
                  Chaque collaborateur télécharge <strong>l'application</strong>
                </p>
                <img
                  srcSet="/assets/app_1.png 1x, /assets/app_1@2x.png 2x"
                  alt=""
                />
              </div>
              <div className="flex-1 mx-8 mb-20 md:mb-0 flex flex-col items-center justify-center">
                <div
                  className="text-4xl font-black text-[#025D8C]"
                  style={{
                    backgroundImage:
                      "linear-gradient(180deg, transparent 55%, #bae6fd 0, #bae6fd)",
                  }}
                >
                  2.
                </div>
                <p className="text-lg text-slate-600 my-8">
                  Je <strong>créé un groupe</strong> pour mon entreprise, que je
                  partage en interne
                </p>
                <img
                  srcSet="/assets/app_2.png 1x, /assets/app_2@2x.png 2x"
                  alt=""
                />
              </div>
              <div className="flex-1 mx-8 mb-20 md:mb-0 flex flex-col items-center justify-center">
                <div
                  className="text-4xl font-black text-[#025D8C]"
                  style={{
                    backgroundImage:
                      "linear-gradient(180deg, transparent 55%, #bae6fd 0, #bae6fd)",
                  }}
                >
                  3.
                </div>
                <p className="text-lg text-slate-600 my-8">
                  Chaque collaborateur accède au groupe et{" "}
                  <strong>partage ses objets</strong>
                </p>
                <img
                  srcSet="/assets/app_3.png 1x, /assets/app_3@2x.png 2x"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <div className="w-full h-20 relative ">
          <WaveBlueTop className="absolute h-12 bottom-0" />
          <WaveSkyTop className="absolute" />
        </div>
        {/* <div className="w-full h-32"></div> */}
        <section className="bg-[#025D8C] text-white">
          <div className="max-w-6xl mx-auto py-32 px-6 sm:px-8 relative">
            <div className="font-semibold text-xl text-sky-300 mb-2">
              {/*Pourquoi l'adopter*/}
            </div>
            <h2 className="text-4xl font-bold tracking-tight sm:text-5xl mb-32">
              Un avantage concret
              <br /> pour vos collaborateurs
            </h2>
            <div className="flex flex-col-reverse md:flex-row mb-32 relative">
              <div className="absolute -top-12 -left-32 h-64 w-64 bg-white bg-opacity-10 rotate-12 rounded-xl" />
              <div className="flex-1 flex">
                <div className="w-12 h-w-12 self-start shrink-0">
                  <img src="/assets/avantage_icon_1.png" alt="" />
                </div>
                <div className="flex flex-col text-left ml-8">
                  <h3 className="text-2xl mb-8">
                    Un nouveau service sur le lieu de travail
                  </h3>
                  <p className="font-light">
                    myGarage permet de tirer profit du lieu de travail et du
                    lien de confiance entre collaborateurs pour développer un
                    nouveau service innovant de partage d’objets.
                  </p>
                </div>
              </div>
              <div className="flex-1 flex min-w-[40%] max-w-[80%] mx-auto mb-10 md:mb-0">
                <Avantage1
                  style={{
                    height: 330,
                    // margin: "auto",
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row mb-32 relative">
              <div className="absolute -top-12 -right-32 h-64 w-64 bg-white bg-opacity-10 rotate-12 rounded-xl" />
              <div className="flex-1 flex min-w-[40%] max-w-[80%] mx-auto mb-10 md:mb-0">
                <Avantage2
                  style={{
                    height: 330,
                    // margin: "auto",
                  }}
                />
              </div>
              <div className="flex-1 flex">
                <div className="w-12 h-w-12 self-start shrink-0">
                  <img src="/assets/avantage_icon_2.png" alt="" />
                </div>
                <div className="flex flex-col text-left ml-8">
                  <h3 className="text-2xl mb-8">
                    Une action directement intégrée à démarche RSE
                  </h3>
                  <p className="font-light">
                    myGarage permet de tirer profit du lieu de travail et du
                    lien de confiance entre collaborateurs pour développer un
                    nouveau service innovant de partage d'objets.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row mb-10 relative">
              <div className="absolute -top-12 -left-32 h-64 w-64 bg-white bg-opacity-10 rotate-12 rounded-xl" />
              <div className="flex-1 flex">
                <div className="w-12 h-w-12 self-start shrink-0">
                  <img src="/assets/avantage_icon_3.png" alt="" />
                </div>
                <div className="flex flex-col text-left ml-8">
                  <h3 className="text-2xl mb-8">
                    Un coup de pouce pour le pouvoir d'achat
                  </h3>
                  <p className="font-light">
                    Un objet emprunté, c'est un achat évité. Aidez vos
                    collaborateurs à faire plus, avec moins, et à emprunter,
                    plutôt qu'acheter !
                  </p>
                </div>
              </div>
              <div className="flex-1 flex min-w-[40%] max-w-[80%] mx-auto mb-10 md:mb-0">
                <Avantage3
                  style={{
                    height: 330,
                    // margin: "auto",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="w-full h-16 relative">
          <WaveBlueBottom className="absolute h-12" />
          <WaveSkyBottom className="absolute" />
        </div>
        <section>
          <div className="max-w-6xl mx-auto py-32 px-6 sm:px-8 relative">
            <div className="font-semibold text-xl text-sky-600 mb-2">
              {/*Témoignages*/}
            </div>
            <h2 className="text-4xl font-bold tracking-tight sm:text-5xl mb-10">
              Ils nous font confiance
            </h2>
            <div className="flex flex-col lg:flex-row items-center justify-center">
              <img
                style={{ width: 200 }}
                className="mx-4 mb-6 lg:mb-0"
                src="/assets/testimonial_1.png"
                alt=""
              />
              <img
                style={{ width: 100 }}
                className="mx-4 mb-6 lg:mb-0"
                src="/assets/testimonial_2.webp"
                alt=""
              />
              <img
                style={{ width: 120 }}
                className="mx-4 mb-6 lg:mb-0"
                src="/assets/testimonial_3.png"
                alt=""
              />
              <img
                style={{ width: 150 }}
                className="mx-4 mb-6 lg:mb-0"
                src="/assets/testimonial_4.png"
                alt=""
              />
              <img
                style={{ width: 150 }}
                className="mx-4 mb-6 lg:mb-0"
                src="/assets/testimonial_5.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section
          id="demo"
          style={{
            backgroundImage: `url(assets/demo.jpg)`,
          }}
        >
          <div className="max-w-6xl mx-auto py-32 px-6 sm:px-8 relative">
            <div className="flex flex-wrap">
              <div className="text-left max-w-xl">
                <div className="font-semibold text-xl text-sky-600 mb-2">
                  Contact
                </div>
                <h2 className="text-2xl font-bold tracking-tight mb-10">
                  Envie de découvrir myGarage ?<br />
                  Contactez notre équipe !
                </h2>
                <p className="italic text-xl font-light mt-20 mb-10">
                  ”Nous avons imaginé myGarage pour répondre au besoin
                  grandissant de réduire notre impact sur l'environnement.
                  <br />
                  <br />
                  Eviter d'acheter, pour produire moins, c'est pour nous un
                  combat du quotidien.
                  <br />
                  <br />
                  Faites-en aussi celui de vos collaborateurs !”
                </p>
                <p className="font-medium text-right">
                  Julien & Valère, fondateurs.
                </p>
              </div>
              <div className="flex-1 mx-4">
                <div className="mx-auto max-w-md">
                  <InlineWidget url="https://calendly.com/mygarage/30min?primary_color=0284c7" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="text-left">
        <div className="py-12 md:py-16">
          <div className="max-w-6xl mx-auto px-6 sm:px-8">
            <div className="grid gap-2 text-gray-500 text-center">
              <p>Made with ❤️ in the Alps</p>
              <p>© 2022 Cyclic SAS. Tous droits réservés.</p>
              <a
                className="hover:underline"
                href="https://my-garage.notion.site/Conditions-g-n-rales-d-utilisation-bd34c63c124746acacaba4164c96a632"
              >
                Conditions Générales d'Utilisation
              </a>
              <a
                className="hover:underline"
                href="https://my-garage.notion.site/Charte-sur-le-respect-de-la-vie-priv-e-e0160a4bfe704df59c9b543e9972bb16"
              >
                Charte vie privée
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Enterprises;
